<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Gratuidade" : "Cadastrar Gratuidade" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Configurações da Gratuidade</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="3">
              <span class="primary--text">Orgão Regulador</span>
              <v-autocomplete
                v-model="dados.id_orgao"
                :rules="fieldRules"
                item-text="descricao"
                item-value="id"
                required
                :items="orgaos"
                placeholder="Informe o orgão regulador"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="4">
              <span class="primary--text">Gratuidades</span>
              <v-autocomplete
                v-model="dados.id_gratuidades"
                :rules="fieldRules"
                item-text="descricao"
                item-value="id"
                required
                :items="gratuidades"
                placeholder="Informe a gratuidade"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="5">
              <span class="primary--text">Tipo de Serviço</span>
              <v-autocomplete
                v-model="dados.id_tiposervico"
                :rules="fieldRules"
                item-text="tipo_servico"
                item-value="id"
                required
                :items="tiposervico"
                placeholder="Informe o tipo de serviço"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Período de Vendas</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="2">
              <span class="primary--text">Quantidade</span>
              <v-text-field
                v-model="dados.periodo_quant_inicial"
                v-mask-number
                placeholder="Informe a quantidade"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Tipo do Período</span>
              <v-select
                v-model="dados.tipo_periodovenda_inicial"
                :items="['Minutos', 'Horas', 'Dias']"
                placeholder="Informe o tipo do período"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>
            <v-col cols="12" sm="1" class="d-flex justify-center align-center">
              <span class="title black--text">Até</span>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">Quantidade</span>
              <v-text-field
                v-model="dados.periodo_quant_final"
                v-mask-number
                placeholder="Informe a quantidade"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <span class="primary--text">Tipo do Período</span>
              <v-select
                v-model="dados.tipo_periodovenda_final"
                :items="['Minutos', 'Horas', 'Dias']"
                placeholder="Informe o tipo do período"
                class="mt-1 custom-solo-selection"
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-card class="mb-15">
        <v-card-title
          class="primary white--text d-flex justify-space-between"
          style="height: 64px"
        >
          <span class="mt-n4">{{
            !dados.fixas
              ? "Quantidade de Poltronas Livres"
              : "Número das Poltronas Fixas"
          }}</span>
          <v-checkbox
            v-model="dados.fixas"
            label="Poltronas Fixas"
            dark
            class="pa-0 ma-0 mt-1"
          ></v-checkbox>
        </v-card-title>

        <v-card-text v-if="!dados.fixas" class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="2">
              <span class="primary--text">Quantidade</span>
              <v-text-field
                v-model="dados.poltrona_livres"
                v-mask-number
                placeholder="Informe a quantidade"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else class="pt-7 px-7">
          <v-row dense>
            <v-col cols="12" sm="2">
              <span class="primary--text">Número</span>
              <v-text-field
                v-model="numero"
                v-mask="'00'"
                @keypress.enter="addNumber(numero)"
                placeholder="Informe o número"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12">
              <v-chip
                v-for="(numeros, i) in dados.poltronas_fixas"
                :key="i"
                close
                @click:close="removeNumber(i)"
                color="primary"
                class="mr-2"
                >{{ numeros }}</v-chip
              >
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      orgaos: [],
      tiposervico: [],
      gratuidades: [],
      numero: "",
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        fixas: false,
        poltrona_livres: null,
        poltronas_fixas: [],
        tipo_periodovenda_final: "Dias",
        tipo_periodovenda_inicial: "Dias",
        periodo_quant_final: 0,
        periodo_quant_inicial: 0,
        id_tiposervico: 0,
        id_orgao: 0,
        id_gratuidades: 0,
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    this.loaderTipoServico("tiposservico");
    this.loaderGratuidades("gratuidades");
    this.loaderOrgaos("orgaoregulador");
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/gerargratuidades/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.dados.poltronas_fixas = JSON.parse(this.dados.poltronas_fixas);
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    addNumber(numero) {
      if (this.dados.poltronas_fixas.indexOf(numero) == -1 && numero != "") {
        this.dados.poltronas_fixas.push(numero);
        this.numero = null;
      } else {
        this.message.text = "Número já cadastrado!";
        this.message.snacshow = true;
        this.message.type = "error";
      }
    },
    removeNumber(indice) {
      this.dados.poltronas_fixas.splice(indice, 1);
    },
    loaderTipoServico(table) {
      this.$http
        .get(`/${table}`)
        .then((r) => {
          this.tiposervico = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loaderOrgaos(table) {
      this.$http
        .get(`/${table}`)
        .then((r) => {
          this.orgaos = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    loaderGratuidades(table) {
      this.$http
        .get(`/${table}`)
        .then((r) => {
          this.gratuidades = r.data;
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      !this.dados.fixas
        ? (this.dados.poltronas_fixas = [])
        : (this.dados.poltrona_livres = 0);
      this.dados.poltronas_fixas = JSON.stringify(this.dados.poltronas_fixas);
      if (this.id) {
        this.$http
          .post(`/gerargratuidades/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push(
                  "/cadastros-operacionais/gerar-gratuidades/gerenciar"
                ); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/gerargratuidades/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push(
                  "/cadastros-operacionais/gerar-gratuidades/gerenciar"
                );
              }, 1500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
